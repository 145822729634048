<template>
  <header data-w-id="3b38d427-d68a-25e1-0a46-bfd5b8ec59a3" data-animation="default" data-collapse="medium"
    data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="uui-navbar02_component w-nav">
    <div class="uui-navbar02_container">
      <a @click="$router.push('/')" class="uui-navbar02_logo-link w-nav-brand">
        <div class="uui-logo_component-2"><img src="@/assets/images/Logo-1.svg" loading="lazy"
            alt="Untitled UI logotext" class="uui-logo_logotype-2"><img src="images/untitled-ui-logo.png" loading="lazy"
            alt="Logo" class="uui-logo_image-2"></div>
      </a>
      <nav role="navigation" class="uui-navbar02_menu w-nav-menu">
        <div class="uui-navbar02_menu-left">
          <!--           <a @click="$router.push('/')" class="uui-navbar02_link w-nav-link">Home</a> -->
          <a href="https://www.iduam.com/#productos" class="uui-navbar02_link w-nav-link">Productos</a>
          <a href="https://www.iduam.com/#precios" class="uui-navbar02_link w-nav-link">Precios</a>
          <a href="https://www.iduam.com/#podcast" class="uui-navbar02_link w-nav-link">Podcast</a>
          <a href="https://www.iduam.com/#casos" class="uui-navbar02_link w-nav-link">Noticias</a>
          <div data-hover="true" data-delay="300" data-w-id="3b38d427-d68a-25e1-0a46-bfd5b8ec59b3"
            class="uui-navbar02_menu-dropdown w-dropdown">
            <!--             <div class="uui-navbar02_dropdown-toggle w-dropdown-toggle">
              <div class="uui-dropdown-icon-2 w-embed"><svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 7.5L10 12.5L15 7.5" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg></div>
              <div>Recursos</div>
            </div> -->
            <nav data-w-id="3b38d427-d68a-25e1-0a46-bfd5b8ec59b8" style="opacity:0"
              class="uui-navbar02_dropdown-list w-dropdown-list">
              <div class="uui-navbar02_container">
                <div class="uui-navbar02_dropdown-content">
                  <div class="uui-navbar02_dropdown-content-left">
                    <div class="uui-navbar02_dropdown-link-list">
                      <h4 class="uui-navbar02_heading">Convierte en aliado</h4>
                      <a href="#" class="uui-navbar02_dropdown-link w-inline-block">
                        <div class="uui-navbar02_icon-wrapper">
                          <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M22 11.0857V12.0057C21.9988 14.1621 21.3005 16.2604 20.0093 17.9875C18.7182 19.7147 16.9033 20.9782 14.8354 21.5896C12.7674 22.201 10.5573 22.1276 8.53447 21.3803C6.51168 20.633 4.78465 19.2518 3.61096 17.4428C2.43727 15.6338 1.87979 13.4938 2.02168 11.342C2.16356 9.19029 2.99721 7.14205 4.39828 5.5028C5.79935 3.86354 7.69279 2.72111 9.79619 2.24587C11.8996 1.77063 14.1003 1.98806 16.07 2.86572M22 4L12 14.01L9 11.01"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              </path>
                            </svg></div>
                        </div>
                        <div class="uui-navbar02_item-right">
                          <div class="uui-navbar02_item-heading">Sé un impulsor </div>
                          <div class="uui-text-size-small-3 hide-mobile-landscape-2">Únete a nuestro equipo de ventas y
                            obtén ingresos extra recomendando iDUAM a nuevas personas.</div>
                        </div>
                      </a>
                      <a href="#" class="uui-navbar02_dropdown-link w-inline-block">
                        <div class="uui-navbar02_icon-wrapper">
                          <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M8.5 14.6667C8.5 15.9553 9.54467 17 10.8333 17H13C14.3807 17 15.5 15.8807 15.5 14.5C15.5 13.1193 14.3807 12 13 12H11C9.61929 12 8.5 10.8807 8.5 9.5C8.5 8.11929 9.61929 7 11 7H13.1667C14.4553 7 15.5 8.04467 15.5 9.33333M12 5.5V7M12 17V18.5M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              </path>
                            </svg></div>
                        </div>
                        <div class="uui-navbar02_item-right">
                          <div class="uui-navbar02_item-heading">Obtén beneficios </div>
                          <div class="uui-text-size-small-3 hide-mobile-landscape-2">Invita a tus amigos a conocer iDUAM
                            y recibe recompensas exclusivas por cada referido exitoso.</div>
                        </div>
                      </a>
                    </div>
                    <div class="uui-navbar02_dropdown-link-list">
                      <h4 class="uui-navbar02_heading">Recursos</h4>
                      <a href="podcast.html" class="uui-navbar02_dropdown-link w-inline-block">
                        <div class="uui-navbar02_icon-wrapper">
                          <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="25" height="24" viewbox="0 0 25 24"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M20.333 19V16H7.33301C5.67615 16 4.33301 17.3431 4.33301 19M9.13301 22H17.133C18.2531 22 18.8132 22 19.241 21.782C19.6173 21.5903 19.9233 21.2843 20.115 20.908C20.333 20.4802 20.333 19.9201 20.333 18.8V5.2C20.333 4.07989 20.333 3.51984 20.115 3.09202C19.9233 2.71569 19.6173 2.40973 19.241 2.21799C18.8132 2 18.2531 2 17.133 2H9.13301C7.45285 2 6.61277 2 5.97104 2.32698C5.40655 2.6146 4.94761 3.07354 4.65999 3.63803C4.33301 4.27976 4.33301 5.11984 4.33301 6.8V17.2C4.33301 18.8802 4.33301 19.7202 4.65999 20.362C4.94761 20.9265 5.40655 21.3854 5.97104 21.673C6.61277 22 7.45285 22 9.13301 22Z"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              </path>
                            </svg></div>
                        </div>
                        <div class="uui-navbar02_item-right">
                          <div class="uui-navbar02_item-heading">Podcast</div>
                          <div class="uui-text-size-small-3 hide-mobile-landscape-2">Escucha nuestro podcast y mantente
                            informado sobre todas las novedades y avances de iDUAM.</div>
                        </div>
                      </a>
                      <a href="https://www.youtube.com/watch?v=mngYYO0TCbU&amp;list=PL8N_kFNQpOETsNm5qOPQSLEVDMKKDnZ3h"
                        target="_blank" class="uui-navbar02_dropdown-link w-inline-block">
                        <div class="uui-navbar02_icon-wrapper">
                          <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="24" height="24" viewbox="0 0 24 24"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              </path>
                              <path
                                d="M9.5 8.96533C9.5 8.48805 9.5 8.24941 9.59974 8.11618C9.68666 8.00007 9.81971 7.92744 9.96438 7.9171C10.1304 7.90525 10.3311 8.03429 10.7326 8.29239L15.4532 11.3271C15.8016 11.551 15.9758 11.663 16.0359 11.8054C16.0885 11.9298 16.0885 12.0702 16.0359 12.1946C15.9758 12.337 15.8016 12.449 15.4532 12.6729L10.7326 15.7076C10.3311 15.9657 10.1304 16.0948 9.96438 16.0829C9.81971 16.0726 9.68666 15.9999 9.59974 15.8838C9.5 15.7506 9.5 15.512 9.5 15.0347V8.96533Z"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              </path>
                            </svg></div>
                        </div>
                        <div class="uui-navbar02_item-right">
                          <div class="uui-navbar02_item-heading">Tutoriales</div>
                          <div class="uui-text-size-small-3 hide-mobile-landscape-2">Aprende a usar todas las funciones
                            de iDUAM con guías en video fáciles y rápidas de seguir.</div>
                        </div>
                      </a>
                    </div>
                    <div class="uui-navbar02_dropdown-link-list">
                      <h4 class="uui-navbar02_heading">La empresa</h4>
                      <a href="#" class="uui-navbar02_dropdown-link w-inline-block">
                        <div class="uui-navbar02_icon-wrapper">
                          <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="25" height="24" viewbox="0 0 25 24"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M4.66699 21L4.66699 4M4.66699 13H12.067C12.627 13 12.9071 13 13.121 12.891C13.3091 12.7951 13.4621 12.6422 13.558 12.454C13.667 12.2401 13.667 11.9601 13.667 11.4V4.6C13.667 4.03995 13.667 3.75992 13.558 3.54601C13.4621 3.35785 13.3091 3.20487 13.121 3.10899C12.9071 3 12.627 3 12.067 3H6.26699C5.70694 3 5.42691 3 5.213 3.10899C5.02484 3.20487 4.87186 3.35785 4.77599 3.54601C4.66699 3.75992 4.66699 4.03995 4.66699 4.6V13ZM13.667 5H20.067C20.627 5 20.9071 5 21.121 5.10899C21.3091 5.20487 21.4621 5.35785 21.558 5.54601C21.667 5.75992 21.667 6.03995 21.667 6.6V13.4C21.667 13.9601 21.667 14.2401 21.558 14.454C21.4621 14.6422 21.3091 14.7951 21.121 14.891C20.9071 15 20.627 15 20.067 15H15.267C14.7069 15 14.4269 15 14.213 14.891C14.0248 14.7951 13.8719 14.6422 13.776 14.454C13.667 14.2401 13.667 13.9601 13.667 13.4V5Z"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              </path>
                            </svg></div>
                        </div>
                        <div class="uui-navbar02_item-right">
                          <div class="uui-navbar02_item-heading">Nosotros</div>
                          <div class="uui-text-size-small-3 hide-mobile-landscape-2">Descubre nuestra historia, valores
                            y misión en el mundo de la telemedicina y monitoreo remoto de salud.</div>
                        </div>
                      </a>
                      <a href="#" class="uui-navbar02_dropdown-link w-inline-block">
                        <div class="uui-navbar02_icon-wrapper">
                          <div class="uui-icon-1x1-xsmall-6 w-embed"><svg width="25" height="24" viewbox="0 0 25 24"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M21.6666 11.5C21.6666 16.1944 17.861 20 13.1666 20C12.0898 20 11.0598 19.7998 10.1118 19.4345C9.93844 19.3678 9.85177 19.3344 9.78285 19.3185C9.71506 19.3029 9.66599 19.2963 9.59648 19.2937C9.5258 19.291 9.44826 19.299 9.29318 19.315L4.17216 19.8444C3.68392 19.8948 3.43979 19.9201 3.29579 19.8322C3.17036 19.7557 3.08494 19.6279 3.06219 19.4828C3.03609 19.3161 3.15274 19.1002 3.38605 18.6684L5.02171 15.6408C5.15641 15.3915 5.22376 15.2668 5.25427 15.1469C5.2844 15.0286 5.29168 14.9432 5.28204 14.8214C5.27229 14.6981 5.21819 14.5376 5.10999 14.2166C4.82246 13.3636 4.66662 12.45 4.66662 11.5C4.66662 6.80558 8.4722 3 13.1666 3C17.861 3 21.6666 6.80558 21.6666 11.5Z"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                              </path>
                            </svg></div>
                        </div>
                        <div class="uui-navbar02_item-right">
                          <div class="uui-navbar02_item-heading">Noticias</div>
                          <div class="uui-text-size-small-3 hide-mobile-landscape-2">Mantente al día con nuestras
                            novedades, comunicados oficiales y artículos de prensa destacados.</div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="uui-navbar02_dropdown-content-right">
                    <div class="uui-navbar02_dropdown-content-wrapper">
                      <div class="uui-navbar02_dropdown-blog-item-wrapper">
                        <a href="#" class="uui-navbar02_blog-item w-inline-block">
                          <div class="uui-navbar02_blog-image-wrapper"><img src="images/nav-image.jpg" loading="eager"
                              sizes="100vw" srcset="images/nav-image-p-500.jpg 500w, images/nav-image.jpg 720w"
                              alt="Portrait of a woman" class="uui-navbar02_blog-image"></div>
                          <div class="uui-navbar02_blog-content">
                            <div class="uui-navbar02_item-heading">¡Muy pronto! </div>
                            <div class="uui-text-size-small-3 text-style-2lines"><strong>Asistente Inteligente
                                IA<br></strong>Un compañero virtual impulsado por inteligencia artificial que te ayudará
                              y<br>acompañará cada día.</div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="uui-navbar02_dropdown-right-overlay-absolute"></div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div class="uui-navbar02_menu-right">
          <div class="uui-navbar02_button-wrapper">
            <a target="_blank" href="https://pacientes.iduam.com/"
              class="uui-button-secondary-gray-7 show-tablet-2 w-inline-block">
              <div>Registrarse</div>
            </a>
            <!--             <a target="_blank" href="https://pacientes.iduam.com/" class="uui-button-tertiary-gray-2 hide-tablet-3 w-inline-block">
              <div>Registrarse</div>
            </a> -->

            <div class="text-center d-flex justify-center" style="gap: 18px; margin: 0 auto;">
              <a href="https://www.youtube.com/@iduam6042" target="_blank">
                <v-icon style="font-size: 32px; cursor: pointer;" color="#20A3FF">fa-youtube</v-icon>
              </a>
              <a href="https://www.instagram.com/iduamapp/" target="_blank">
                <v-icon style="font-size: 32px; cursor: pointer;" color="#20A3FF">fa-instagram</v-icon>
              </a>
              <a href="https://www.facebook.com/appiduam" target="_blank">
                <v-icon style="font-size: 32px; cursor: pointer;" color="#20A3FF">fa-facebook</v-icon>
              </a>
              <a href="https://www.tiktok.com/@iduam.salud" target="_blank">
                <img style="width: 34px; height: 34px; cursor: pointer; " src="../../assets/images/logo-tiktok.png"
                  alt="">
              </a>
            </div>

            <a target="_blank" href="https://pacientes.iduam.com/" class="uui-button w-inline-block">
              <div>Iniciar Sesión Paciente</div>
            </a>
          </div>
        </div>
      </nav>
      <div class="uui-navbar02_menu-button w-nav-button">
        <div class="menu-icon_component-2">
          <div class="menu-icon_line-top-2"></div>
          <div class="menu-icon_line-middle-2">
            <div class="menu-icon_line-middle-inner-2"></div>
          </div>
          <div class="menu-icon_line-bottom-2"></div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

export default {
  name: "Header",
  data: () => ({
    isMobile: false,
    showNav: false
  }),
  methods: {
    closeNav() {
      $(".navbar-collapse").collapse('hide');
    }
  },
  mounted() {
    // Cargar el script de jQuery
    const script1 = document.createElement('script');
    script1.src = "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=67bdc556c384e19b2a97bae5";
    script1.type = 'text/javascript';
    script1.integrity = 'sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=';
    script1.crossOrigin = 'anonymous';
    document.body.appendChild(script1);

    // Cargar el script de Webflow
    const script2 = document.createElement('script');
    script2.src = '/js/webflow.js';
    script2.type = 'text/javascript';
    document.body.appendChild(script2);

    const script3 = document.createElement('script');
    script3.type = 'text/javascript';
    script3.innerHTML = `!function (o, c) { 
      var n = c.documentElement, t = " w-mod-"; 
      n.className += t + "js", 
      ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && 
      (n.className += t + "touch") 
    }(window, document);`;
    document.body.appendChild(script3);


  }
  // beforeDestroy() {
  //   if (typeof window !== "undefined") {
  //     window.removeEventListener("resize", this.onResize, { passive: true });
  //   }
  // },
  // mounted() {
  //   this.onResize();
  //   window.addEventListener("resize", this.onResize, { passive: true });
  // },
  // methods: {
  //   onResize() {
  //     this.isMobile = window.innerWidth < 992;
  //   },
  // },
};
</script>
<style>
@import "../../assets/css/normalize.css";
@import "../../assets/css/webflow.css";
@import "../../assets/css/iduam-86f9f2.webflow.css";


@media (max-width:991px) and (min-width:768px) {
  html.w-mod-js:not(.w-mod-ix) [data-w-id="3b38d427-d68a-25e1-0a46-bfd5b8ec59b8"] {
    height: 0px;
  }
}

@media (max-width:767px) and (min-width:480px) {
  html.w-mod-js:not(.w-mod-ix) [data-w-id="3b38d427-d68a-25e1-0a46-bfd5b8ec59b8"] {
    height: 0px;
  }
}

@media (max-width:479px) {
  html.w-mod-js:not(.w-mod-ix) [data-w-id="3b38d427-d68a-25e1-0a46-bfd5b8ec59b8"] {
    height: 0px;
  }
}
</style>