import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{staticStyle:{"z-index":"123"},attrs:{"persistent":"","max-width":!_vm.$vuetify.breakpoint.mobile ? '600px' : '100vw',"content-class":"custom-dialog"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[(_vm.dialog)?_c('div',{staticClass:"modal-overlay"},[_c('div',{staticClass:"modal"},[_c('h2',{staticClass:"modal-title"},[_vm._v("Avatar Interactivo TuClon")]),_c('div',{staticClass:"avatar-preview"},[(_vm.selectedAvatar)?_c('img',{staticClass:"avatar-image",attrs:{"src":_vm.selectedAvatar.image}}):_vm._e()]),_c('div',{staticClass:"step-content"},[(_vm.currentStep === 1)?_c('div',{staticClass:"step"},[_c('h5',[_vm._v("Ingresa tu número de Whatsapp a continuación para recibir un Avatar Interactivo")]),_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"rules":_vm.requiredPhone,"label":"Whatsapp","counter":11,"maxlength":"11","required":"","placeholder":"56XXXXXXXXX"},model:{value:(_vm.numbers),callback:function ($$v) {_vm.numbers=$$v},expression:"numbers"}}),_c('div',{staticClass:"button-container"},[_c('div'),_vm._v(" "),_c(VBtn,{staticClass:"button primary",attrs:{"loading":_vm.loading},on:{"click":_vm.sendWhatsappAssistantIduam}},[_vm._v("Solicitar Avatar")])],1)],1)],1):_vm._e(),(_vm.currentStep === 2)?_c('div',{staticClass:"step"},[_c('div',{staticStyle:{"display":"flex","align-items":"center","gap":"8px","text-align":"center"}},[_c('h4',{staticClass:"mb-0 mt-0"},[_vm._v("Tu Avatar Interactivo ha sido enviado!")]),_c(VIcon,{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")])],1),_c('div',{staticClass:"button-container"},[_c('button',{staticClass:"button primary",on:{"click":_vm.closeDialog}},[_vm._v("CERRAR")])])]):_vm._e()]),_c('button',{staticClass:"close-modal",on:{"click":function($event){_vm.dialog = false}}},[_vm._v("×")])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }