<template>
    <v-dialog v-model="dialog" persistent :max-width="!$vuetify.breakpoint.mobile ? '600px' : '100vw'" content-class="custom-dialog" style="z-index: 123;">
        <v-card>
            <div v-if="dialog" class="modal-overlay">
                <div class="modal">
                    <h2 class="modal-title">Avatar Interactivo TuClon</h2>

                    <div class="avatar-preview">
                        <img v-if="selectedAvatar" :src="selectedAvatar.image" class="avatar-image" />
                    </div>

                    <!-- Step Indicator -->
<!--                     <div class="step-indicator">
                        <div v-for="step in 2" :key="step"
                            :class="['step-dot', { active: currentStep >= step, completed: currentStep > step }]"
                            @click="currentStep = step">
                            {{ step }}
                        </div>
                        <div class="step-line"></div>
                    </div> -->

                    <div class="step-content">
                        <!-- Step 1: Personalidad -->
                        <div v-if="currentStep === 1" class="step">
                            <h5>Ingresa tu número de Whatsapp a continuación para recibir un Avatar Interactivo</h5>
                            <v-form ref="form" v-model="valid">
                            <v-text-field
                                v-model="numbers"
                                :rules="requiredPhone"
                                label="Whatsapp"
                                :counter="11"
                                maxlength="11"
                                required
                                placeholder="56XXXXXXXXX"></v-text-field>
                            <div class="button-container">
                                <div></div> <!-- Spacer -->
                                <v-btn :loading="loading" class="button primary" @click="sendWhatsappAssistantIduam">Solicitar Avatar</v-btn>
                            </div>
                            </v-form>
                        </div>

                        <!-- Step 2: avatar enviado -->
                        <div v-if="currentStep === 2" class="step">
                            <div style="display: flex; align-items: center; gap: 8px; text-align: center;">
                                <h4 class="mb-0 mt-0">Tu Avatar Interactivo ha sido enviado!</h4>
                                <v-icon color="success">mdi-check-circle</v-icon>
                            </div>
                            <div class="button-container">
                                <button class="button primary" @click="closeDialog">CERRAR</button>
<!--                                 <button :loading="loading" class="button success" @click="sendWhatsappAssistantIduam">Crear</button> -->
                            </div>
                        </div>
                    </div>

                    <button class="close-modal" @click="dialog = false">×</button>
                </div>
            </div>

            <!-- Snackbar -->
<!--             <div v-if="snackbar" class="snackbar">
                ¡Avatar creado con éxito!
                <button class="close-button" @click="sendWhatsappAssistantIduam">×</button>
            </div> -->
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'CreateAvatar',
    props: ['dialog', 'selectedAvatar', 'closeDialog'],
    data: () => ({
        currentStep: 1,
        selectedAvatar: null,
        personality: 'amigable',
        voiceTone: 'medio',
        snackbar: false,

        loading: false,
        numbers: '',
        requiredPhone: [
            v => !!v || 'Este campo es obligatorio',
            v => ( !!v && /^\d+$/.test(v) ) || 'Ingrese sólo números',
            v => (v && v.length === 11) || '"El número debe tener 11 dígitos.'
        ],
        valid: false,
    }),
    mounted() {
    },
    methods: {
        nextStep() {
            this.currentStep = 2
        },
        prevStep() {
            this.currentStep = 1
        },
        sendWhatsappAssistantIduam() {
            if(this.$refs.form.validate()){
            this.loading = true
            const data = {numbers: [this.numbers], avatar: this.selectedAvatar.avatar_id, avatar_media: this.selectedAvatar.avatar_media, newtemplate: false}

            fetch('https://devapis.anubys.cl:8019/api/auth/sendWhatsappAssistantIduam', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json' 
                },
                body: JSON.stringify(data)
                }).then(response => response.json()).then(result => {
                    this.loading = false
                    this.snackbar = true;
                    this.numbers = ''
                    this.currentStep = 2
                })
                .catch(error => {
                    this.snackbar = true;
                    this.loading = false
                });
            }
        }
    },
    watch: {
        selectedAvatar() {
            this.currentStep = 1
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.title {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.card {
    width: 300px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.card-content {
    padding: 15px;
}

.card-content h3 {
    margin-top: 0;
    margin-bottom: 8px;
}

.card-content p {
    margin: 0;
    color: #666;
}

/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
}

.modal-title {
    text-align: center;
    margin-bottom: 20px;
    color: #4285f4;
}

.avatar-preview {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.avatar-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 3px solid #4285f4;
}

/* Step indicator */
.step-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
}

.step-line {
    position: absolute;
    top: 50%;
    left: 25%;
    right: 25%;
    height: 2px;
    background-color: #e0e0e0;
    z-index: 1;
}

.step-dot {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
    border: 2px solid #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 40px;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: all 0.3s;
}

.step-dot.active {
    background-color: #4285f4;
    border-color: #4285f4;
    color: white;
}

.step-dot.completed {
    background-color: #34a853;
    border-color: #34a853;
    color: white;
}

.step-content {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
}

.step {
    animation: fadeIn 0.3s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.step h3 {
    margin-top: 0;
    margin-bottom: 20px;
    color: #333;
}

.radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
}

.radio-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.radio-option:hover {
    background-color: #f5f5f5;
}

.radio-label {
    margin-left: 8px;
}

.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
}

.primary {
    background-color: #4285f4;
    color: white;
}

.primary:hover {
    background-color: #3367d6;
}

.secondary {
    background-color: #f1f1f1;
    color: #333;
}

.secondary:hover {
    background-color: #e0e0e0;
}

.success {
    background-color: #34a853;
    color: white;
}

.success:hover {
    background-color: #2e8b47;
}

/* Snackbar styles */
.snackbar {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #34a853;
    color: white;
    padding: 15px 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1001;
}

.close-button {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    margin-left: 15px;
}

@media (max-width: 768px) {
    .card-container {
        flex-direction: column;
        align-items: center;
    }

    .radio-group {
        flex-direction: column;
        gap: 10px;
    }

    .step-dot {
        margin: 0 20px;
    }
}
</style>
