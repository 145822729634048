<template>
  <div>
    <Header />
    <Home />
    <Footer />
<!--     <Drawer /> -->
<div @click="modificarShadowDOM">
      <elevenlabs-convai class="fade-in" ref="miEmbed" agent-id="wLb7gpYGBHWVPqnOAApN">
      </elevenlabs-convai>
      <!--       <elevenlabs-convai ref="miEmbed" agent-id="VrgM6LMnYQOQKXgIVXJm">
      </elevenlabs-convai> -->
      <img
        class="fade-in"
        style="
          width: 306px;
          height: 170px;
          position: fixed;
          right: 33px;
          bottom: 125px;
          border-radius: 15px;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          z-index: 123;
          border: 1px solid #efefef;
        "
        src="../../assets/images/sandra.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import Home from "./Home.vue";
import Drawer from "../drawer/Drawer.vue";
import Header from "../commons/Header.vue";
import Footer from "../commons/Footer.vue";

export default {
  name: "Index",
  components: {
    Home,
    Drawer,
    Header,
    Footer,
  },
  data() {
    return {
      key: 0,
    };
  },
  methods: {
    modificarShadowDOM() {
      const embedElement = this.$refs.miEmbed;

      if (embedElement.shadowRoot) {
        const shadowRoot = embedElement.shadowRoot;

        const style = document.createElement("style");
        style.textContent = `
          div > div._box_1f9vw_39 {
            width: 307px;
            min-height: 100px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            border-top: none;
          }
          
        `;
        shadowRoot.appendChild(style);

        const miElemento = shadowRoot.querySelector("div > div._box_1f9vw_39");
        if (miElemento) {
          miElemento.style.width = "307px";
          miElemento.style.minHeight = "100px";
          miElemento.style.borderTopLeftRadius = "0";
          miElemento.style.borderTopRightRadius = "0";
          miElemento.style.borderBottomLeftRadius = "15px";
          miElemento.style.borderBottomRightRadius = "15px";
          miElemento.style.borderTop = "none";
        }
      } else {
        console.error("El shadowRoot no está disponible");
      }
    },
  },
  mounted() {
    const script = document.createElement("script");
    script.src = "https://elevenlabs.io/convai-widget/index.js";
    script.async = true;
    script.type = "text/javascript";
    document.body.appendChild(script);

    setTimeout(() => {
      this.modificarShadowDOM();
    }, 200);

    document.addEventListener("DOMContentLoaded", function () {
      const elementos = document.querySelectorAll(".fade-in");

      setTimeout(function () {
        elementos.forEach(function (el) {
          el.classList.add("visible");
        });
      }, 300);
    });
/*     const plugin = document.createElement("script");
    plugin.setAttribute("src", "/js/bootstrap.js");
    document.head.appendChild(plugin);

    const plugin2 = document.createElement("script");
    plugin2.setAttribute("src", "/js/jquery-1.11.3.min.js");
    document.head.appendChild(plugin2);

    const plugin3 = document.createElement("script");
    plugin3.setAttribute("src", "/js/jquery.scrollTo-min.js");
    document.head.appendChild(plugin3);

    const plugin4 = document.createElement("script");
    plugin4.setAttribute("src", "/js/jquery.magnific-popup.min.js");
    document.head.appendChild(plugin4);

    const plugin5 = document.createElement("script");
    plugin5.setAttribute("src", "/js/jquery.nav.js");
    document.head.appendChild(plugin5);

    const plugin6 = document.createElement("script");
    plugin6.setAttribute("src", "/js/wow.js");
    document.head.appendChild(plugin6);

    const plugin7 = document.createElement("script");
    plugin7.setAttribute("src", "/js/countdown.js");
    document.head.appendChild(plugin7);

    const plugin8 = document.createElement("script");
    plugin8.setAttribute("src", "/js/custom.js");
    document.head.appendChild(plugin8);

    const plugin9 = document.createElement("script");
    plugin9.setAttribute("src", "/js/plugins.js");
    document.head.appendChild(plugin9); */

  },
};
</script>

<style>
.fade-in {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.fade-in.visible {
  opacity: 1;
}
</style>