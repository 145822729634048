const endpoints ={
login: '/api/auth/login',
userData: '/api/userData',
user: '/api/app/user/details',
videollamada: '/api/calls/videoCall',
videoEmail: '/api/calls/videoMail',
speciality: '/api/company/specialities',
callEvent: '/api/events',
postFormContac: '/api/attention/contact',

//payment
getPaymentValue: '/api/app/planes/getToPayData',
startPaymentPlan: '/api/app/pagos/newPlanPayment',
getOrderStatusByToken: '/api/auth/getOrderStatusByToken',
getFinalPaymenInfo: '/api/pagos/getFinalPaymenInfo',

//Registro
register: '/api/register',
getToken: 'api/token',

//SERVICIOS
getServices: '/api/company/serviceStatus',

fileUpload: 'api/calls/videoCall/{videoCallID}/publicAttachment',
getFilePublicUrl: 'api/attachments/',

//CONVERSATIONS
newConversation: 'api/admin/conversation/conversation',
newMessageConversation: 'api/admin/conversation/message-conversation',

//Contact Form
sendContactMessage: 'api/contact/sendContactMessage'
}

export {endpoints}